import { FillButton, TextButton } from '@codecademy/gamut';
import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import * as React from 'react';
import { ReactNode, useContext, useMemo, useRef } from 'react';

import {
  AppHeaderAction,
  AppHeaderClickHandler,
  AppHeaderItem,
  AppHeaderSearch,
  FormattedAppHeaderItems,
} from '..';
import { AppBar } from '../AppBar';
import { TeamsRevampContext } from '../GlobalHeader';
import { formatUrlWithRedirect } from '../GlobalHeader/urlHelpers';
import { HeaderHeightArea } from '../HeaderHeightArea';
import { NotificationsDropdown } from '../Notifications/NotificationsDropdown';
import { AppHeaderNotificationSettings } from '../Notifications/types';
import { useHeaderNotifications } from '../Notifications/useHeaderNotifications';
import { AppHeaderCatalogDropdown } from './AppHeaderElements/AppHeaderCatalogDropdown';
import { AppHeaderDropdown } from './AppHeaderElements/AppHeaderDropdown';
import { AppHeaderLink } from './AppHeaderElements/AppHeaderLink';
import { AppHeaderListItem } from './AppHeaderElements/AppHeaderListItem';
import { AppHeaderLogo } from './AppHeaderElements/AppHeaderLogo';
import { AppHeaderProvider } from './AppHeaderElements/AppHeaderProvider';
import { AppHeaderResourcesDropdown } from './AppHeaderElements/AppHeaderResourcesDropdown';
import { useHeaderSearch } from './Search/useHeaderSearch';
import { appHeaderMobileBreakpoint } from './shared';

export * from './Search/consts';

export type AppHeaderProps = AppHeaderAction & {
  items: FormattedAppHeaderItems;
  notifications?: AppHeaderNotificationSettings;
  redirectParam?: string;
  search: AppHeaderSearch;
  isAnon: boolean;
  /**
   * used to conditonally hide the default search icon and notification bell
   */
  hideRightButtonDefaults?: boolean;
  isTeams?: boolean;
};

export const StyledAppBar = styled(AppBar)(
  css({
    boxShadow: `none`,
  })
);

export const StyledNavBar = styled.ul(
  css({
    alignItems: 'stretch',
    display: `flex`,
    padding: 0,
    listStyle: `none`,
    margin: 0,
    width: `100%`,
  })
);

const spacing = {
  standard: 8,
  enterprise: 12,
} as const;

export const mapItemToElement = (
  action: AppHeaderClickHandler,
  item: AppHeaderItem,
  isAnon: boolean,
  isTeams?: boolean,
  redirectParam?: string,
  onKeyDown?: (event: React.KeyboardEvent) => void,
  mobile = false
): ReactNode => {
  switch (item.type) {
    case 'logo':
      return <AppHeaderLogo action={action} item={item} />;
    case 'link':
      return <AppHeaderLink mx={0} action={action} item={item} />;
    case 'dropdown':
    case 'profile-dropdown':
      return (
        <AppHeaderDropdown onKeyDown={onKeyDown} action={action} item={item} />
      );
    case 'catalog-dropdown':
      return (
        <AppHeaderCatalogDropdown action={action} item={item} isAnon={isAnon} />
      );
    case 'resources-dropdown':
      return (
        <AppHeaderResourcesDropdown
          action={action}
          item={item}
          isAnon={isAnon}
        />
      );
    case 'render-element':
      return item.renderElement();
    case 'text-button':
      return (
        <TextButton
          size={mobile ? 'small' : 'normal'}
          onClick={(event: React.MouseEvent) => action(event, item)}
          data-testid={item.dataTestId}
          href={
            item.redirect
              ? formatUrlWithRedirect(item.href, redirectParam)
              : item.href
          }
          variant={isTeams ? 'primary' : 'interface'}
        >
          {item.text}
        </TextButton>
      );
    case 'fill-button':
      return (
        <FillButton
          size={mobile ? 'small' : 'normal'}
          data-testid={item.dataTestId}
          href={
            item.redirect
              ? formatUrlWithRedirect(item.href, redirectParam)
              : item.href
          }
          onClick={(event: React.MouseEvent) => action(event, item)}
          variant="interface"
        >
          {item.text}
        </FillButton>
      );
  }
};

export const AppHeader: React.FC<AppHeaderProps> = ({
  action,
  isAnon,
  hideRightButtonDefaults,
  items,
  notifications,
  redirectParam,
  search,
  isTeams,
}) => {
  const displayTeamsRevamp = useContext(TeamsRevampContext);

  const menuContainerRef = useRef<HTMLUListElement>(null);

  const [notificationsBell, notificationsView] = useHeaderNotifications({
    settings: notifications,
    Renderer: NotificationsDropdown,
  });
  const [searchButton, searchPane] = useHeaderSearch({
    ...search,
  });

  const right = useMemo(() => {
    const defaultItems = hideRightButtonDefaults
      ? []
      : [searchButton, ...(notificationsBell ? [notificationsBell] : [])];

    if (displayTeamsRevamp && isTeams) {
      const teamsSpecificMenuItems = items.right.slice(0, -1);
      const profile = items.right[items.right.length - 1];

      return [...teamsSpecificMenuItems, ...defaultItems, profile];
    }

    return [...defaultItems, ...items.right];
  }, [
    searchButton,
    notificationsBell,
    hideRightButtonDefaults,
    items,
    displayTeamsRevamp,
    isTeams,
  ]);

  const mapItemsToElement = <T extends AppHeaderItem[]>(
    items: T,
    side: 'left' | 'right'
  ) => {
    return items.map((item, index) => {
      const margin = hideRightButtonDefaults
        ? spacing.enterprise
        : spacing.standard;
      return (
        <AppHeaderListItem
          key={item.id}
          mr={margin}
          ml={side === 'right' && index === 0 ? 'auto' : margin}
        >
          {mapItemToElement(action, item, isAnon, isTeams, redirectParam)}
        </AppHeaderListItem>
      );
    });
  };

  return (
    <HeaderHeightArea
      display={{ _: 'none', [appHeaderMobileBreakpoint]: 'block' }}
    >
      <AppHeaderProvider>
        <StyledAppBar aria-label="Main" as="nav">
          <StyledNavBar ref={menuContainerRef}>
            {mapItemsToElement(items.left, 'left')}
            {mapItemsToElement(right, 'right')}
          </StyledNavBar>
        </StyledAppBar>
        {notificationsView}
        {searchPane}
      </AppHeaderProvider>
    </HeaderHeightArea>
  );
};

import { gql } from '@mono/data/codegen/client/graphql-gateway';

gql(`
  fragment rawPathCardData on Path {
    id
    slug
    goal
    title
    lessonCount: contentItemCount(contentItemType: [Lesson])
    trackCount
    enrollmentStatus @skip(if: $skipEnrollmentStatus)
    shortDescription
    difficulty
    metrics {
      medianDurationHours
    }
	  __typename
  }
`);

gql(`
  fragment rawTrackCardData on Track {
    id
    slug
    title
    lessonCount: contentItemCount(contentItemType: [Lesson])
    grantsCertificate
    enrollmentStatus @skip(if: $skipEnrollmentStatus)
    pro: proExclusive
    shortDescription
    difficulty
    metrics {
      medianDurationHours
    }
	  __typename
  }
`);

gql(`
  fragment rawExtCourseCardData on ExternalCourse {
    id
    slug
    title
    grantsCertificate
    enrollmentStatus @skip(if: $skipEnrollmentStatus)
    pro: proExclusive
    difficulty
    durationHours
    shortDescription
    longDescription
    __typename
  }
`);

gql(`
  fragment rawExtPathCardData on ExternalPath {
    id
    slug
    title
    courseCount
    difficulty
    durationHours
    shortDescription
    longDescription
    __typename
  }
  `);

// for your convenience, see the fetchCardDataBySlugs from this lib
export const curriculumCardDataBySlugsQuery = gql(`
  query curriculumCardDataBySlugs($slugs: [String!]!, $skipEnrollmentStatus: Boolean = false) {
    paths(slug: $slugs) {
      ...rawPathCardData
    }
    tracks(slug: $slugs, onlyStandalone: true) {
      ...rawTrackCardData
    }
    externalCourses(slug: $slugs) {
      ...rawExtCourseCardData
    }
    externalPaths(slug: $slugs) {
      ...rawExtPathCardData
    }
  }
`);
